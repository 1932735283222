import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const BackgroundSection = ({ imageData, children, className, bgColor }) => (
  // Set ImageData.
  <BackgroundImage
    Tag="section"
    className={className}
    fluid={imageData}
    backgroundColor={bgColor}
    style={{
      // Defaults are overwrite-able by setting one or each of the following:
      backgroundSize: `contain`,
      backgroundPosition: `center`,
      backgroundRepeat: `no-repeat`,
    }}
  >
    {children}
  </BackgroundImage>
)

const BGImageBlog = styled(BackgroundSection)`
  width: 100%;
  background-size: contain;
  height: 100%;
`

export default BGImageBlog
