import React, { useEffect, useContext, useState } from "react"
import styled from "styled-components"
import { FaArrowLeft } from "react-icons/fa"
import { rhythm } from "../../../config/typography"
import AnimationLink from "../nav/AnimationLink"
import { NavContext } from "../../context/NavContext"
import { useTransitionState } from "gatsby-plugin-transition-link/hooks"
import { motion, AnimatePresence } from "framer-motion"

const AllWorks = ({ pageContext }) => {
  const [backLink, setBackLink] = useState(`/works`)
  const [backText, setBackText] = useState(`All Works`)
  const { isNavOpen } = useContext(NavContext)
  const state = useTransitionState()

  useEffect(() => {
    if (pageContext !== undefined) {
      if (pageContext.isWorkshopBlog) {
        setBackLink(`/blog`)
        setBackText(`All Posts`)
      } else {
        setBackLink(`/works`)
        setBackText(`All Works`)
      }
    }
  }, [])
  return (
    <>
      <StyledAnimLink
        to={backLink}
        ariaLabel={`See ${backText}`}
        enterState={{ fromAllWorks: true }}
        exitState={{ fromAllWorks: false }}
        state={{ fromAllWorks: true }}
        isNavOpen={isNavOpen}
      >
        <AnimatePresence>
          <motion.div
            className="smallshadow"
            initial={{
              x: 100,
              opacity: 0,
              transition: {
                duration: 0.35,
              },
            }}
            exit={{
              x: 100,
              opacity: 0,
              transition: {
                duration: 0.35,
              },
            }}
            animate={{
              x: 0,
              opacity: 1,
              transition: {
                duration: 0.35,
                delay: 1,
              },
            }}
          >
            <FaArrowLeft color="#2c3e50" size={rhythm(1 / 2)} />
            <span className="noselect">View {backText}</span>
          </motion.div>
        </AnimatePresence>
      </StyledAnimLink>
    </>
  )
}

const StyledAnimLink = styled(AnimationLink)`
  z-index: 500000000;

  position: fixed;
  bottom: 0;
  right: 0;
  div {
    background: white;
    background-size: cover;
    padding: ${rhythm(1 / 2)};
  }

  span {
    margin-left: ${rhythm(1 / 2)};
    text-transform: uppercase;
    color: ${props => props.theme.brand.primary};
  }
`

export default AllWorks
