/* eslint-disable react/prop-types */
import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import SEO from "../seo"
import { Container, Row, Col } from "react-grid-system"
import { rhythm } from "../../../config/typography"
import AllWorks from "./AllWorks"
import AnimationLink from "../../components/nav/AnimationLink"
import { Header1 } from "../../elements/"
import BGImageBlog from "../../elements/BGImageBlog"
import { below } from "../../../config/utilities"
import TransitionContext from "../../context/TransitionContext"

const wsMDXLayout = ({ data: { mdx }, location, pageContext }) => (
  <>
    <SEO
      title={mdx.frontmatter.title + ` - ` + mdx.frontmatter.subtitle}
      description={mdx.frontmatter.description}
    />
    {` `}
    <AllWorks pageContext={pageContext} />
    <TransitionContext location={location.pathname}>
      <div
        css={`
          height: 500px;
          ${below.xs`
            height:400px;
            `}
          img {
            margin: ${rhythm(3)};
          }
        `}
      >
        {mdx.frontmatter.heroimage ? (
          <BGImageBlog
            bgColor={mdx.frontmatter.bgcolor}
            imageData={mdx.frontmatter.heroimage.childImageSharp.fluid}
          />
        ) : (
          <BGImageBlog
            bgColor={mdx.frontmatter.bgcolor}
            imageData={mdx.frontmatter.featuredimage.childImageSharp.fluid}
          />
        )}
      </div>
      <Wrapper>
        <BlogPostTemplate
          mdx={mdx}
          description={mdx.frontmatter.description}
          tags={mdx.frontmatter.tags}
          title={mdx.frontmatter.title}
          subtitle={mdx.frontmatter.subtitle}
          draft={mdx.frontmatter.draft}
          content={mdx.body}
        />
      </Wrapper>
    </TransitionContext>
  </>
)

export const BlogPostTemplate = ({ tags, title, subtitle, mdx }) => (
  <section className="section" style={{ position: `relative` }}>
    <Container fluid style={{ margin: 0, padding: 0 }}>
      <Row align="center" justify="center">
        <Col
          xl={5.5}
          lg={6}
          md={7}
          sm={9}
          xs={11}
          style={{
            marginTop: `${rhythm(1)}`,
          }}
        >
          <Header1 lineOne={title} lineTwo={subtitle} marginTop />
        </Col>
      </Row>
      <Row
        align="center"
        justify="center"
        style={{ maxWidth: `75ch`, margin: `auto` }}
      >
        <Col md={9} xs={11} className="blogpost">
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </Col>
      </Row>
      {tags && tags.length ? (
        <Row align="center" justify="center">
          <Col xs="content">
            <Row
              align="center"
              justify="center"
              css={`
                text-align: center;
                margin: ${rhythm(0.25)};
                position: relative;
                &:after {
                  left: 0;
                  content: "";
                  display: inline-flex;
                  margin: auto;
                  transition: all 0.5s ease-in-out;
                  width: 100%;
                  height: 1px;
                  bottom: -4px;
                  background: ${props => props.theme.brand.primaryfade2};
                  position: absolute;
                  z-index: 100;
                  transition: all 0.5s cubic-bezier(0.89, 0.29, 0.16, 0.93);
                }
                h4 {
                  font-weight: 600;
                  color: ${props => props.theme.brand.primaryfade};
                  margin: ${rhythm(0.25)};
                }
              `}
            >
              <h4>Tags</h4>
            </Row>
            <Row className="taglist" align="center" justify="between">
              {tags.slice(0, 3).map(tag => (
                <Col xs="content" key={tag + `tag`}>
                  <li
                    css={`
                      * {
                        margin: 0 auto;
                        opacity: 0.75;
                        transition: all 0.5s ease-in-out;
                      }
                      &:hover {
                        * {
                          opacity: 1;
                          transition: all 0.5s ease-in-out;
                        }
                      }
                    `}
                  >
                    <AnimationLink
                      ariaLabel={`Link to blog posts tagged with '${tag}'`}
                      to={`/tags/${tag}/`}
                      state={{ fromTagLink: true }}
                    >
                      <h6
                        css={`
                          font-size: ${rhythm(0.6)};
                        `}
                      >
                        {` `}
                        {tag}
                      </h6>
                    </AnimationLink>
                  </li>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ) : null}
      <Row className="taglist" align="center" justify="center">
        <AnimationLink
          ariaLabel={`Browse all tags.'`}
          to={`/tags`}
          state={{ fromTagLink: true }}
          className="alltags"
        >
          Browse all tags
        </AnimationLink>
      </Row>
    </Container>
  </section>
)

export default wsMDXLayout

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: content;
  width: 100vw;
  position: relative;
  background-color: white;
`

export const pageQuery = graphql`
  query WSBlogPostByID($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      cropDefault: frontmatter {
        featuredimage {
          childImageSharp {
            resize(width: 1024, height: 1024, cropFocus: CENTER) {
              src
              width
              height
            }
          }
        }
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        subtitle
        description
        tags
        bgcolor
        featuredimage {
          childImageSharp {
            fluid(maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heroimage {
          childImageSharp {
            fluid(maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
